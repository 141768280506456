<template>
  <nice-dialog
    :model-value="visible"
    :title="$t('localHero.modal.kicker')"
    :close-on-click-modal="false"
    append-to-body
    @close="close"
    class="p-12"
    width="700px"
  >
    <section class="flex justify-between items-center px-8">
      <p class="text-4xl font-medium">
        Highlight des
        <br />
        Tages verfügbar!
        <!-- Stehe 24 Stunden
        <promo-highlighter>ganz oben</promo-highlighter>
        in der Ergebnisliste bei ImmoScout24 -->
      </p>
      <img src="@/assets/images/extension/scout.svg" width="150" />
    </section>
    <section class="flex mb-4">
      <main class="mt-10 pl-4">
        <section class="ml-8">
          <article v-for="(reason, $idx) in localHeroModalReasons" :key="$idx" class="flex mb-3 break-normal">
            <aside class="mr-3" style="color: #03ffd0">
              <fa-icon :name="reason.icon" class="text-xl fa-fw" />
            </aside>
            <main class="flex-grow">
              <div v-if="reason.hint">
                <p class="text-lg mb-0 inline" v-html="reason.title"></p>
                <nice-tooltip :content="reason.hint">
                  <fa-icon name="question-circle" />
                </nice-tooltip>
              </div>
              <p v-else class="text-xl mb-0 font-bold" v-html="reason.title"></p>
            </main>
          </article>
        </section>
        <nice-checkbox v-model="localHeroAutoPublish" class="ml-2 mt-5 flex" style="min-width: 250px; max-width: 300px">
          <span class="ml-2 text-sm break-normal font-normal">
            {{ $t("localHero.modal.automaticalPublishSetting") }}
          </span>
        </nice-checkbox>
        <footer class="flex flex-col justify-center pt-4 text-center">
          <nice-button
            style="min-width: 200px; max-width: 300px; background-color: #03ffd0; border-color: #03ffd0"
            @click="submitLocalHero('yes')"
            class="mb-3"
          >
            {{ $t("localHero.modal.cta.yes") }}
          </nice-button>
          <a
            href="#"
            @click.prevent="submitLocalHero('no')"
            class="text-gray-600"
            style="min-width: 200px; max-width: 300px"
          >
            {{ $t("localHero.modal.cta.no") }}
          </a>
        </footer>
      </main>
      <aside class="shrink-0 text-center">
        <img src="@/assets/images/Flowfact_Localhero.svg" width="320" />
      </aside>
    </section>
    <br />
    <p class="text-sm px-8 pb-4" v-html="$t('localHero.modal.footnote')"></p>
  </nice-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { useI18n } from "vue-i18n"

export default defineComponent({
  setup() {
    const { tm } = useI18n()
    return { tm }
  },
  props: ["visible", "record", "portal"],
  data() {
    return {
      localHeroAutoPublish: false,
      localHeroModalReasons: this.tm("localHero.modal.reasons"),
    }
  },

  methods: {
    submitLocalHero(answer: "yes" | "no") {
      this.$emit("update:visible", false)

      if (answer == "no") this.rejectLocalHero()
      else this.exportAsLocalHero()
    },
    exportAsLocalHero() {
      this.$api
        .mutation(
          "exportPropertyAsLocalHero",
          {
            propertyId: this.record.id,
            portalId: this.portal ? this.portal.id : null,
            publishEverywhereAfterwards: !this.portal,
          },
          "localHeroBooking { createdAt startsAt endsAt }"
        )
        .then(({ localHeroBooking }) => {
          if (this.localHeroAutoPublish) {
            this.$db.updateBroker({ localHeroAutoPublish: true })

            this.$api.mutation("createProductTracking", {
              eventType: "local_hero_auto_opted_in_via_popup",
            })
          }
          this.record.skipDirty = true
          this.record.updated_at = (window as any).moment().format()
          this.record.local_hero_booking = localHeroBooking
          App.flashy(`Export mit Local Hero Platzierung wurde erfolgreich gestartet.`)
        })
        .catch(this.$axios.handleError)
    },
    rejectLocalHero() {
      const portals = this.portal ? [this.portal] : this.$db.shopData.connections.filter(b => !b.parentConnectionId)

      this.$api
        .mutation("exportProperties", {
          propertyIds: [this.record.id],
          portalIds: portals.map(p => p.id),
          modus: "ONLINE",
          localHeroRejected: true,
        })
        .then(_ => {
          App.flashy(`Export zu allen Portalen wurde erfolgreich gestartet.`)
        })
        .catch(this.$axios.handleError)
    },
    close() {
      if (!this.visible) return

      this.$api.mutation("createProductTracking", {
        eventType: "local_hero_cancelled",
        entityType: "Property",
        entityId: this.record.id,
      })

      this.$emit("update:visible", false)
    },
  },
})
</script>

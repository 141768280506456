<script setup lang="ts">
import { useOneSystem } from "@/integrations/use-one-system"
import useCore from "@/plugins/use-core"
import { OS_EA48_LINK } from "@/views/admin/extensions/Energieausweis48.vue"
import { computed } from "vue"

const props = defineProps<{
  record: any
  field: any
}>()

const { db } = useCore()
const { isOneSystem } = useOneSystem()

const order_url = computed(() => {
  // This is snake case, becuase the PropertyForm component formats all property fields to snake case
  // via formatRecord
  return isOneSystem.value ? OS_EA48_LINK : props.record.energieausweis48_order_url
})

const options = computed(() => {
  const mapping = db.shopData.propertyOptionsMapping.energy_certificate_availability
  return Object.keys(mapping).map(key => ({ id: key, name: mapping[key] }))
})

const openPage = () => {
  window.open(order_url.value, "_blank")
}
</script>

<template>
  <dv-row>
    <template v-slot:label>
      <span v-html="field.formField.label"></span>
      <span v-if="field.mandatory">*</span>
    </template>
    <div
      :class="[
        { 'mandatory-outline': field.mandatory && !field.readonly && !record[field.fieldName] && field.submitAttempt },
        'flex w-100',
      ]"
    >
      <nice-select
        v-bind="field.formField.additionalParams?.(field, record[field.fieldName])"
        :disabled="field.readonly"
        filterable
        clearable
        default-first-option
        v-model="record[field.fieldName]"
        :placeholder="$t('detailView.placeholder')"
        :class="{ 'disabled-visibility-dropdown': field.readonly }"
        :style="!record[field.fieldName] && field.color && `background-color: ${field.color}`"
        class="rounded"
        :options="options"
        :data-value-missing="!record[field.fieldName]"
      ></nice-select>
      <hover-action v-if="record.energieausweis48_order_url && !field.readonly" class="ml-2" @click="openPage">
        <span class="text-green-600">Bestellen</span>
      </hover-action>
    </div>
  </dv-row>
</template>

<style scoped>
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>

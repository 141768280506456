import { Location } from "@/interfaces"
import * as _ from "lodash"

type LocationOption = { value: number; label: string; parentLocationId?: number }

export const getLocationGroups = (locations: Location[]): LocationOption[] => {
  const renamedLocations = locations.map(({ id: value, name: label, parentLocationId }) => ({
    value,
    label,
    parentLocationId,
  }))

  const lookup = {}

  renamedLocations.forEach(cur => (lookup[cur.value] = cur))
  renamedLocations.forEach(location => {
    if (!location.parentLocationId || location.parentLocationId === location.value) return
    const entry = lookup[location.parentLocationId]
    if (!entry) return
    return (entry.children || (entry.children = [])).push(location)
  })
  return renamedLocations.filter(location => !location.parentLocationId)
}

/* Builds hierarchical path names for locations without recursion
 * @param locations Array of location objects
 * @returns Array of locations with hierarchical path names
 */
export function getLocationGroupsFlat(locations: Location[]): Location[] {
  // Create lookup map for O(1) access
  const locationMap: Record<number, any> = {}
  locations.forEach(loc => (locationMap[loc.id] = loc))

  // Results array
  const result = locations.map(location => {
    // Start with the current location
    const pathParts = [location.name]
    const visitedIds = new Set<number>([location.id])
    let currentId = location.parentLocationId

    // Trace path up the hierarchy
    while (currentId && !visitedIds.has(currentId)) {
      const parent = locationMap[currentId]

      // Break if parent doesn't exist
      if (!parent) break

      // Add this parent to the path and mark as visited
      pathParts.unshift(parent.name)
      visitedIds.add(currentId)

      // Move up to next parent
      currentId = parent.parentLocationId
    }

    // Create result with hierarchical path name and original data
    return {
      id: location.id,
      name: pathParts.join(" → "),
      parentLocationId: location.parentLocationId,
    }
  })

  // Sort by name and return
  return _.orderBy(result, "name", "asc")
}

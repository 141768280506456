<template>
  <div>
    <dv-row :label="`${field.formField.label} ${field.mandatory ? '*' : ''}`" :hint="field.hint">
      <div v-redacted class="wrapper rounded">
        <div
          class="input"
          :class="{
            'disabled-visibility-email': field.readonly,
            'mandatory-outline': field.mandatory && !field.readonly && !emails.length && field.submitAttempt,
          }"
          style="height: auto"
        >
          <div class="d-flex flex-wrap" @click="setFocus" style="cursor: text">
            <div
              v-for="(email, $idx) in emails"
              :key="$idx"
              style=""
              class="item d-flex rounded align-items-center"
              :class="{ first: $idx === 0 }"
            >
              <span class="max-w-sm truncate">{{ email }}</span>
              <div class="actions d-flex ml-1">
                <fa-icon v-show="$idx !== 0" class="icon px-1" name="arrow-up" @click="setPrimary($idx)"></fa-icon>
                <copy-to-clipboard :text="email" />
                <nice-tooltip v-if="!field.readonly" :content="$t('detailView.remove')">
                  <fa-icon class="icon px-1" name="times" @click="remove($idx)" />
                </nice-tooltip>
              </div>
            </div>
            <div style="flex: 1 1 20px">
              <input
                ref="input"
                style="width: 100%"
                class="invisible-input"
                @keyup.enter="add(newEmail)"
                @blur="add(newEmail, true)"
                @keydown.backspace="handleBackspace()"
                type="text"
                v-model.trim="newEmail"
                :readonly="isReadOnly"
              />
            </div>
          </div>
        </div>
      </div>
    </dv-row>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import { Field } from "../../interfaces"
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"

export default defineComponent({
  props: {
    record: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    field: {
      type: Object as PropType<Field>,
      required: true,
    },
  },
  methods: {
    remove(index: number) {
      this.emails.splice(index, 1)
      this.record[this.field.fieldName] = this.emails
    },
    add(email, silent = false) {
      if (!email) return
      if (!this.validateEmail(email)) {
        if (silent) {
          this.newEmail = ""
        } else {
          App.alert(this.$t("detailView.emailError"))
        }
        return
      }

      this.record[this.field.fieldName] = [...this.emails, email]

      this.newEmail = ""
    },
    setPrimary(index: number) {
      const oldValue = this.emails[index]
      const newValue = this.emails.filter((e, i) => i !== index)
      newValue.unshift(oldValue)

      this.record[this.field.fieldName] = newValue
    },
    handleBackspace() {
      this.$nextTick(() => {
        if (!this.newEmail) this.emails.pop()
      })
    },
    setFocus() {
      ;(this.$refs.input as HTMLElement).focus()
    },
    getLabel(index: number) {
      if (this.emails.length === 1) return "E-Mail"
      if (this.emails.length > 1 && index === 0) return this.$t("detailView.emailLabel")
      if (this.emails.length > 1) return "E-Mail (" + (index + 1) + ")"
    },
    validateEmail(email: string) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
  },
  data() {
    return {
      newEmail: "",
    }
  },
  computed: {
    isReadOnly() {
      return coerceBooleanAttribute(!!this.field?.readonly)
    },
    emails(): string[] {
      return this.record[this.field.fieldName] || []
    },
  },
})
</script>

<style lang="scss" scoped>
.flex-wrap {
  flex-wrap: wrap;
}

.wrapper {
  width: 100%;
}

.actions {
  opacity: 1;
  transform: scale(0.9);
  margin-top: 1px;
}
.item:hover .actions {
  opacity: 1;
}

.item {
  margin: 2px;
  padding: 2px 6px;
  padding-right: 2px;
  border-radius: 3px;
  font-size: 13px;
  background: rgba(0, 120, 223, 0.2);
  &.first {
    background-color: #b9e6b9;
  }
}

.icon {
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

.input {
  border: none;
  width: 100%;
  min-height: 28.38px;
  line-height: normal;
  line-height: initial;
  padding: 4px 6px;
  border-radius: 3px;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset, rgba(15, 15, 15, 0.1) 0px 1px 1px inset;
  background: rgba(242, 241, 238, 0.6);
  min-width: 0;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invisible-input {
  border: 0;
  height: 100%;
  background-color: transparent;
}

.disabled-visibility-email {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>

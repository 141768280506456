<template>
  <nice-select
    :model-value="modelValue"
    :options="options"
    :multiple="multiple"
    :fixedPosition="fixedPosition"
    :placeholder="$t('brokerDropdownSearch.search')"
    @update:model-value="emit('update:modelValue', $event)"
    @change="emit('change', $event)"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue"
import useCore from "@/plugins/use-core"
const { graphql, axios } = useCore()
let cache

const emit = defineEmits(["update:modelValue", "change"])

interface CSBrokerSelectProps {
  modelValue: any
  fixedPosition?: boolean
  multiple?: boolean
}

const props = withDefaults(defineProps<CSBrokerSelectProps>(), {
  modelValue: null,
  fixedPosition: false,
  multiple: false,
})

const options = ref([])

const fetchData = () => {
  graphql(
    `
      query CommissionSplitBrokers {
        shop {
          commissionSplitBrokers {
            id
            name
          }
        }
      }
    `
  )
    .then(({ shop }) => {
      options.value = shop.commissionSplitBrokers
      cache = options.value
    })
    .catch(axios.handleError)
}

onMounted(() => {
  if (cache) {
    options.value = cache
  } else {
    fetchData()
  }
})
</script>

<template>
  <div class="w-100">
    <nice-input
      ref="newCityInput"
      v-model="inputValue"
      :placeholder="$t(placeholder)"
      size="small"
      class="mb-2"
      :disabled="disabled"
    />
    <el-tag
      :key="tag"
      v-for="tag in modelValue"
      closable
      type="info"
      size="small"
      class="mr-2 mb-1"
      @close="handleClose(tag)"
    >
      {{ tag }}
    </el-tag>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted } from "vue"
import { loadGoogleAPI } from "@/core/3rdparty/GoogleAPI"

const countryMapping = {
  swiss: "ch",
  austria: "at",
  spain: "es",
  hungary: "hu",
}

const {
  modelValue,
  placeholder = "client.queries.form.cityPh",
  disabled = false,
  sellsInternationally = false,
  internationalCountry = null,
} = defineProps<{
  modelValue: string[]
  placeholder?: string
  disabled?: boolean
  sellsInternationally?: boolean
  internationalCountry?: string
}>()

const emit = defineEmits(["update:modelValue"])

const inputVisible = ref(false)
const inputValue = ref("")
const newCityInput = ref(null)

const handleClose = city => {
  const index = modelValue.indexOf(city)
  if (index >= 0) {
    const newValue = [...modelValue]
    newValue.splice(index, 1) // splice modifies in place, the return value is the removed elements
    emit("update:modelValue", newValue)
  }
}

const handleInputConfirm = () => {
  let input = inputValue.value
  let value = modelValue
  if (!value) {
    value = []
  }
  if (input && !value.includes(input)) {
    value.push(input)
  }
  inputVisible.value = false
  inputValue.value = ""
  emit("update:modelValue", value)
}

const googleCountryRestriction = computed(() => {
  if (sellsInternationally) return null
  else internationalCountry ? countryMapping[internationalCountry] : "de"
})

let placeChangeListener
onMounted(async () => {
  const places = await loadGoogleAPI("places")
  const input = newCityInput.value.$refs.input.$refs.input
  const autocomplete = new places.Autocomplete(input, {
    types: ["(cities)"],
    componentRestrictions: !!googleCountryRestriction.value ? { country: googleCountryRestriction.value } : undefined,
  })
  placeChangeListener = autocomplete.addListener("place_changed", event => {
    const place = autocomplete.getPlace()
    if (!place.place_id) return // user wrote random shit
    inputValue.value = place.name
    handleInputConfirm()
  })
})

onUnmounted(() => {
  placeChangeListener.remove()
})
</script>

<style>
/* puts the google places autocomplete dropdown results above the el-dialog modal 2001 zindex. */
.pac-container {
  z-index: 12002 !important;
}
</style>

// global cache key for shop data in indexedDB
export const GLOBAL_SHOP_DATA_KEY = "shop-data"

// maximum depth of the folder tree for outlook folders (navigation pane and folder settings)
export const MAX_FOLDER_TREE_DEPTH = 10

export const MAX_SIGNED_INT = 2147483647

// magic number for permissions indicating that the permission applies to the contact person
export const BROKER_ID_PLACEHOLDER_FOR_CONTACT_PERSON = -1

export const SETTINGS_HEADER_FIXED_WIDTH = "720px"

export const NO_SORTING_KEY = "*STANDARD*"

export const FINDRIVE_TIPSTER_KICKBACK_PERCENT = 3

export enum BrokerStatusTypes {
  SICK = "sick",
  VACATION = "vacation",
  HOME_OFFICE = "home_office",
  OFFICE = "office",
  NO_STATUS = "no_status",
}

type BrokerStatusOption = {
  value: BrokerStatusTypes | null
  label: string
  icon: string
}
export type BrokerStatusMap = Record<BrokerStatusTypes, BrokerStatusOption>
export const BROKER_STATUSES: BrokerStatusMap = {
  [BrokerStatusTypes.SICK]: {
    value: BrokerStatusTypes.SICK,
    label: "admin.broker.sick",
    icon: "thermometer",
  },
  [BrokerStatusTypes.VACATION]: {
    value: BrokerStatusTypes.VACATION,
    label: "admin.broker.vacation",
    icon: "tree-palm",
  },
  [BrokerStatusTypes.HOME_OFFICE]: {
    value: BrokerStatusTypes.HOME_OFFICE,
    label: "admin.broker.homeOffice",
    icon: "home",
  },
  [BrokerStatusTypes.OFFICE]: {
    value: BrokerStatusTypes.OFFICE,
    label: "admin.broker.office",
    icon: "building",
  },
  [BrokerStatusTypes.NO_STATUS]: {
    value: null,
    label: "admin.broker.noStatus",
    icon: "plus",
  },
}

export const ACTIVITY_TYPES = {
  cancelation: "activityTypes.locales.cancelation",
  inquiry: "activityTypes.locales.inquiry",
  reminder: "activityTypes.locales.reminder",
  letter: "activityTypes.locales.letter",
  message: "activityTypes.locales.message",
  policy: "activityTypes.locales.policy",
  note: "activityTypes.locales.note",
  sms: "activityTypes.locales.sms",
  call: "activityTypes.locales.call",
  publishing: "activityTypes.locales.publishing",
  event: "activityTypes.locales.event",
}

<template>
    <nice-input
      size="small"
      type="number"
      v-if="tipsterCommissionType === 'percentage'"
      v-model="commissionPercentageValue"
      :disabled="disabled"
    />
    <currency-input
      v-else
      v-model="commissionValue"
      :currency="currency"
      :disabled="disabled"
    />
    <nice-radio-group
      v-model="tipsterCommissionType"
      class="ml-2 whitespace-nowrap flex-nowrap"
      :disabled="disabled"
      :options="[
        { id: 'percentage', name: '%' },
        { id: 'fixed', name: currency },
      ]"
    />
</template>

<script setup lang="ts">
import { computed, ref } from "vue"

const emits = defineEmits(["update:commission", "update:commissionPercentage"])

const {
  commission = null,
  commissionPercentage = null,
  currency,
  disabled = false
} = defineProps<{
  commission?: number
  commissionPercentage?: number,
  currency: string,
  disabled?: boolean
}>()

const commissionValue = computed({
  get() {
    return commission
  },
  set(modifiedValue) {
    emits("update:commission", modifiedValue)
    emits("update:commissionPercentage", null)
  },
})

const commissionPercentageValue = computed({
  get() {
    return commissionPercentage
  },
  set(modifiedValue) {
    emits("update:commission", null)
    emits("update:commissionPercentage", modifiedValue)
  },
})

const tipsterCommissionType = ref(commissionValue.value != null
    ? "fixed"
    : "percentage")
</script>

import { ActiveRecord } from "@/config/db"

const getCookies = () =>
  document.cookie
    .split(";")
    .map(entry => entry.trim().split("="))
    .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {})

export const isAuthenticated = () => {
  const cookies = getCookies()
  return !!cookies["auth_token"]
}

export const isBackdoor = () => {
  const cookies = getCookies()
  return cookies["backdoor"] === "true" && !(window as any).override_backdoor
}

const adminRoute = (path: string) => `/admin/${path}`

const adminRoutes = (...paths: string[]) => paths.map(p => adminRoute(p))

export const adminPermissionTree: [string[], (broker: any, shop?: any) => boolean, boolean?][] = [
  [adminRoutes("locations"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditUnits")],
  [adminRoutes("newsletter"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToSeeMarketing")],
  [adminRoutes("super_groups"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditGroups")],
  [adminRoutes("settings/pipelines"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditPipelines")],
  [adminRoutes("shopwindows"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditUnits")],
  [adminRoutes("booking_calendars"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToChangeTasks")],
  [
    adminRoutes("settings/variables", "letter_templates"),
    (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditLetterTemplates"),
  ],
  [adminRoutes("franchise"), (db: ActiveRecord) => db.shopData.franchiser],
  [adminRoutes("settings/shop"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditShopData")],
  [adminRoutes("shop/micro_sites"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditLandingpages")],
  [adminRoutes("settings/gdpr"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditGdprData")],
  [adminRoutes("connections"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditPortals")],
  [adminRoutes("extensions"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditExtensions")],
  [adminRoutes("mail_accounts"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditBrokers")],
  [adminRoutes("settings/deals", "custom_fields"), (db: ActiveRecord) => db.broker.admin],
  [
    adminRoutes("settings/properties"),
    (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditPropertySettings"),
  ],
  [
    adminRoutes("settings/contacts"),
    (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditContactsSettings"),
  ],
  [
    adminRoutes("mailgun", "messagebird", "rights", "settings/lead_inquiries", "settings/reports"),
    (db: ActiveRecord) => db.broker.admin,
  ],
  [adminRoutes("settings/tasks"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditTasksSettings")],
  [adminRoutes("settings/automations"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditRecipes")],
  [adminRoutes("settings/media"), (db: ActiveRecord) => db.hasRight("rightToEditMediaSettings")],
  [adminRoutes("settings/api_keys"), (db: ActiveRecord) => db.hasRight("rightToEditApiKeys")],
  [adminRoutes("settings/teams"), (db: ActiveRecord) => db.hasRight("rightToEditTeams")],
  [adminRoutes("settings/pdf_exposees"), (db: ActiveRecord) => db.hasRight("rightToEditPdfExposeeSettings")],
  [adminRoutes("settings/property_report"), (db: ActiveRecord) => db.hasRight("rightToEditPropertyReportSettings")],
  [adminRoutes("settings/events"), (db: ActiveRecord) => db.hasRight("rightToEditEventSettings")],
  [adminRoutes("departments"), (db: ActiveRecord) => db.hasRight("rightToEditDepartments")],
  [adminRoutes("settings/saved_queries"), (db: ActiveRecord) => db.hasRight("rightToEditSavedQuerySettings")],
  [adminRoutes("shop/lead_score"), (db: ActiveRecord) => db.hasRight("rightToEditLeadScoreSettings")],
  [
    adminRoutes("settings/", "settings"),
    (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToEditSettings"),
    true,
  ],
  [
    adminRoutes("shop/immo_valuation"),
    (db: ActiveRecord) => db.broker.admin && db.shopData.activeFeatures.includes("immo_valuation"),
  ],
  [
    adminRoutes("shop/tipster_portal"),
    (db: ActiveRecord) => db.broker.admin && db.shopData.activeFeatures.includes("tipster_portal"),
  ],
  [
    adminRoutes("shop/valuations", "shop/valuations/sprengnetter"),
    db =>
      (db.broker.admin || db.hasRight("rightToSeeMarketing")) &&
      (db.shopData.activeFeatures.includes("ph_valuation") || db.shopData.activeFeatures.includes("sn_valuation")),
  ],
  [adminRoutes("shop/extensions/propnow"), (db: ActiveRecord) => db.broker.admin || db.hasRight("rightToSeeMarketing")],
  [
    adminRoutes("settings/task_pipelines"),
    (db: ActiveRecord) =>
      db.hasRight("rightToEditTaskPipelinesSettings") || db.shopData.activeFeatures.includes("task_pipelines"),
  ],
  [
    adminRoutes("settings/sample_contracts"),
    (db: ActiveRecord) => db.shopData.activeFeatures.includes("sample_contracts"),
  ],
]

export const routeGuard = (route: string, db: ActiveRecord) => {
  if (route === "/") return true
  return adminPermissionTree.reduce((agg, [routes, validator, exact = false]) => {
    return agg && routes.every(r => ((exact ? route === r : route.startsWith(r)) ? validator(db) : true))
  }, true)
}

<template>
  <div v-if="renderComponent">
    <dv-row :hint="field.hint">
      <template v-slot:label>
        <span v-html="$t('clients.formFields.tipster_commission')"></span>
        <span v-if="field.mandatory">*</span>
      </template>
      <div
        :class="[
          {
            'mandatory-outline':
              field.mandatory &&
              !field.readonly &&
              !field.readonly &&
              !record.tipster.commission_percentage &&
              field.submitAttempt,
          },
          'flex w-100',
        ]"
      >
        <commission-input
          :commission="record.tipster.commission"
          @update:commission="value => (record.tipster.commission = value)"
          :commission-percentage="record.tipster.commission_percentage"
          @update:commission-percentage="value => (record.tipster.commission_percentage = value)"
          :currency="currency"
          :disabled="field.readOnly"
        />
      </div>
    </dv-row>
  </div>
</template>

<script>
import { unitMapping } from "@/config/units"
import CommissionInput from "../../CommissionInput"

export default {
  props: ["record", "field"],
  components: {
    CommissionInput
  },
  computed: {
    currency() {
      return unitMapping[this.record.currency || this.$db.shopData.isoCurrency]
    },
    renderComponent() {
      return this.$db.featureActive("tipster_portal") && this.$db.extensionEnabled('commissionsplit') && this.record.tipster
    }
  },
}
</script>

<style scoped>
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>

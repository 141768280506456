<script setup lang="ts">
import { computed } from "vue"
import { useAsyncState } from "@vueuse/core"
import { formatCurrency } from "@/utils/formatters/currency"
import useCore from "@/plugins/use-core"
import eventBus from "@/config/event-bus"
import { FINDRIVE_TIPSTER_KICKBACK_PERCENT } from "@/config/constants"
const props = defineProps(["record", "field"])
const { graphql } = useCore()

const { state: snippetCategories } = useAsyncState(async () => {
  const {
    shop: { snippetCategories },
  } = await graphql(
    `
      query snippetCategories {
        shop {
          snippetCategories {
            id
            name
            snippets(forCurrentBroker: true) {
              id
              name
              subject
            }
          }
        }
      }
    `
  )
  return snippetCategories
}, [])

const findriveSnippetsCategoryName = "Baufiteam"
const findriveTipsterSnippetName = "Einladung Finanzierungsberatung"
const findriveTipsterSnippet = computed(() => {
  const findriveTipsterSnippetCategory = snippetCategories.value
    ? snippetCategories.value.find(category => category.name === findriveSnippetsCategoryName)
    : null

  return findriveTipsterSnippetCategory?.snippets.find(snippet => snippet.name === findriveTipsterSnippetName) || null
})

const possibleKickback = computed(() => {
  const price = props.record.sold_price || 0
  const kickback = price * (FINDRIVE_TIPSTER_KICKBACK_PERCENT / 100)
  const formattedKickback = formatCurrency(kickback, { currency: "EUR", maximumFractionDigits: 0 })
  return kickback ? `${formattedKickback}` : `${FINDRIVE_TIPSTER_KICKBACK_PERCENT}%`
})

const shouldShowBanner = computed(() => {
  return !props.record.cf_finanzierungsbestatigung
})

const quickviewMail = () =>
  eventBus.$emit("quick-view", {
    type: "mail",
    mode: "edit",
    params: {
      source: {
        clientIds: [props.record.client_id].filter(Boolean),
        propertyIds: [props.record.property_id].filter(Boolean),
        projectIds: [props.record.project_id].filter(Boolean),
        snippetId: findriveTipsterSnippet.value.id,
      },
    },
  })
</script>

<template>
  <Transition>
    <section
      v-if="shouldShowBanner"
      class="relative my-8 w-full h-full flex flex-row gap-4 bg-gray-100 items-center p-4"
    >
      <div class="bannerTitle">
        <h2>
          {{ $t("integrations.findriveTipster.banner.title") }}
        </h2>
      </div>
      <p v-html="$t('integrations.findriveTipster.banner.description', { kickback: possibleKickback })"></p>
      <a
        class="inline-block px-8 xl:px-16 whitespace-nowrap py-2 bg-teal-300 text-black font-medium hover:text-black rounded-lg hover:bg-teal-400 transition-colors duration-200"
        href="#"
        @click.prevent="quickviewMail"
      >
        {{ $t("integrations.findriveTipster.banner.cta") }}
      </a>
    </section>
  </Transition>
</template>

<style scoped>
.bannerTitle {
  background-image: url("@/assets/images/IS24_teal_priority_scribble.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: -1rem;
  left: 1.5rem;
  padding: 0.5rem 1rem;
}

.bannerTitle h2 {
  font-size: 1.2rem;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
